import React, { useState } from 'react'
import * as yup from 'yup'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Form from 'components/Form'
import Container from 'components/Container'
import ContactIcon from 'components/icons/Contact'
import Field from 'components/Form/Field'
import IconContainer from 'components/icons/Container'
import { Button, FormControl, TextField } from '@material-ui/core'
import composeHandlers from 'utils/composeHandlers'

const FormInput = ({ name, onChange, onBlur, formatValue, ...props }) => (
  <Field
    name={name}
    render={({
      onBlur: fieldOnBlur,
      onChange: fieldOnChange,
      renderError,
      setValue,
      valid,
      form,
      ...field
    }) => (
      <>
        <TextField
          {...props}
          {...field}
          onBlur={composeHandlers(fieldOnBlur, onBlur)}
          onChange={e => {
            onChange && onChange(e)
            formatValue
              ? setValue(formatValue(e.target.value))
              : fieldOnChange(e)
          }}
          error={!valid}
        />
        {renderError()}
      </>
    )}
  />
)

const Description = props => (
  <p className="text-base leading-snug my-4" {...props} />
)

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Email must be a valid email address')
    .required("Email can't be blank"),
  full_name: yup.string().required("Full name can't be blank"),
  comments: yup.string().required("Comments can't be blank"),
  phone: yup
    .number()
    .required("Phone number can't be blank")
    .positive()
})

const Contact = () => {
  const [sent, setSent] = useState(false)

  return (
    <Layout>
      <Helmet title="Oregon City Smiles - Contact Us" />
      <Container>
        <h1 className="text-3xl text-center">
          <IconContainer color="skyBlue">
            <ContactIcon color="white" size="2rem" />
          </IconContainer>
          Contact Us
        </h1>
        <Description>
          Please feel free to call us with any comments or questions at (503)
          518-3384. If you prefer to contact us online, you may e-mail us any
          questions or comments by filling out the form below. A staff member
          will respond to your e-mail shortly.
        </Description>

        <Description>Thank you for your interest in our practice!</Description>
        <div className="w-full lg:w-9/12 bg-white rounded p-4 mx-auto my-8 shadow-2xl">
          {sent ? (
            <Description>Successfully sent</Description>
          ) : (
            <Form
              validationSchema={schema}
              onSubmit={params =>
                fetch(
                  'https://ins429.dynu.net:60429/oregon-city-smiles/contact_us',
                  {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(params)
                  }
                ).then(() => {
                  setSent(true)
                })
              }
            >
              <FormControl fullWidth margin="dense">
                <FormInput name="full_name" label="Full Name" />
              </FormControl>
              <FormControl fullWidth margin="dense">
                <FormInput name="email" label="Email" />
              </FormControl>
              <FormControl fullWidth margin="dense">
                <FormInput name="phone" label="Phone" />
              </FormControl>
              <FormControl fullWidth margin="dense">
                <FormInput name="comments" label="Comments" />
              </FormControl>
              <FormControl>
                <p className="text-xs">
                  *Personal health information of any kind should not be
                  included in comments sent from this form. Please call our
                  office to communicate personal health information.
                </p>
              </FormControl>
              <FormControl fullWidth margin="dense">
                <div className="flex justify-end">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="bg-open-blue"
                  >
                    Send
                  </Button>
                </div>
              </FormControl>
            </Form>
          )}
        </div>
      </Container>
    </Layout>
  )
}

export default Contact
